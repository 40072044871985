import React, { useRef } from 'react';
import classNames from 'classnames';
import { useDrop } from 'react-dnd'

import { getFieldById } from '../../../components/lets-form';

import './placeholder-wrapper.scss';

const PlaceholderWrapper = ({
  nextField,
  parentField,
  parentFieldTarget,
  parentFieldSubTarget,
  fields,
  onMove = () => {},
  onAppend = () => {},
  onAddField = () => {},
  onAddTemplate = () => {}
}) => {
  const ref = useRef(null)
  const [{ handlerId, isOver }, drop] = useDrop({
    accept: ['card', 'component'],
    collect(monitor) {
      // it's the item being dragged
      const item = monitor.getItem();
      if (!monitor.isOver() || item?.fieldId === parentField.id) {
        return {
          isOver: false,
          handlerId: monitor.getHandlerId()
        };
      }

      return {
        isOver: true,
        handlerId: monitor.getHandlerId()
      };
    },
    drop(item) {
      console.log('droppo sul placeholder', item)
      if (item.dropType === 'field') {
        // dragging a field from the form
        if (!ref.current || item?.fieldId === parentField.id) {
          return;
        }

        // if field is empty, append to the parent, otherwise move
        if (nextField) {
          onMove(item.id, nextField?.id);
        } else {
          onAppend(
            getFieldById(fields, item.id),
            parentField,
            parentFieldTarget,
            parentFieldSubTarget
          );
        }
      } else if (item.dropType === 'component' && !item.template) {
        // dragging a component from the component sidebar
        onAddField(
          item.componentName,
          parentField,
          parentFieldTarget,
          parentFieldSubTarget
        );
      } else if (item.dropType === 'component' && item.template) {
        // dragging a component from the component sidebar
        onAddTemplate(
          item.templateId,
          parentField,
          parentFieldTarget,
          parentFieldSubTarget
        );
      }
    }
  });
  // only allows dropping
  drop(ref)

  return (
    <div
      className={classNames('form-generator-placeholder-wrapper', { hovering: isOver })}
      ref={ref}
      data-handler-id={handlerId}
    >
      <div className="component">&nbsp;</div>
    </div>
  );
};

export { PlaceholderWrapper };
