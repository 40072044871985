import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Header } from 'rsuite';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import { Builder } from '../builder';
import { NavigationBar } from '../../layout';
import { fillIds } from '../../components/lets-form';
import { ErrorPlaceholder, AlchemicLoader } from '../../components';
import { useCurrentUser, useLocalStorage, useNotification } from '../../hooks';
import { CodePlugProvider } from '../../code-plug';
import { upgradeForm } from '../../components/lets-form/generator';

import { useFormVersion } from './hooks/save-form-version';

const ProjectFormPage = () => {
  const { hasFeatureFlag } = useCurrentUser({ redirectToLogin: true });
  let [searchParams] = useSearchParams();
  const { projectId, formId } = useParams();
  const [initialForm, setInitialForm] = useState();
  const notification = useNotification();
  const [isError, setIsError] = useState(false);
  const [persistedSize, setPersistedSize] = useLocalStorage('size', 12);
  const [framework, setFramework] = useState(searchParams.get('framework') || 'react-rsuite5');
  const navigate = useNavigate();

  const { loading, error, loadingError } = useFormVersion(
    formId, projectId,
    {
      onCompleted: data => {
        let formSchema;
        try {
          setFramework(data.form.projectId.framework);
          const json = data.form.versions[0].json;
          formSchema = JSON.parse(json);
          // ensure ids
          formSchema = {
            ...formSchema,
            fields: fillIds(formSchema.fields)
          };
        } catch (e) {
          // set error in case of parsing problems
          setIsError(true);
        }
        // set in state, ask to upgrade
        if (formSchema.version < 2) {
          setInitialForm(upgradeForm(formSchema));
          notification.info(
            'Form schema upgraded!',
            <div>
              The form schema version was upgraded to <em>version 2</em>, the process is automated and replaced deprectated
              components <em>two-columns</em> and <em>three-columns</em> with the more generic <em>columns</em>.<br/>
              <b>Don't forget to save the upgraded form and to export again to your codebase.</b>
            </div>
          );
          return;
        }
        setInitialForm(formSchema);
      }
    }
  );

  useEffect(
    () => {
      // if empty prepare a blank one
      if (_.isEmpty(formId)) {
        const locales = searchParams.get('locales');
        const newForm = {
          version: 2,
          layout: 'vertical',
          fields: [],
          locales: !_.isEmpty(locales) ? locales.split(',') : undefined,
          name: searchParams.get('name') || 'New form'
        };
        setInitialForm(newForm);

        // remove url params, how react-router fucked up the library it's impossible
        // to find how to do this with rr?
        window.history.pushState({}, null, `/projects/${projectId}/new`);
      }

    },
    [formId, searchParams, projectId]
  );

  const handleCancel = useCallback(
    () => {
      // TODO if not changes, then go back or ask
      navigate(`/projects/${projectId}`);
    },
    [projectId, navigate]
  );

  const handleSize = useCallback(
    size => {
      setPersistedSize(size);
    },
    [setPersistedSize]
  );

  return (
    <>
      <Header>
        <NavigationBar />
      </Header>
      <CodePlugProvider
        name="project/form/id"
        plugins={[
          'leave-feedback',
          // Tabs
          'builder-edit-form',
          'builder-edit-field',
          'builder-debug',
          'builder-export',
          'builder-connectors',
          // end Tabs
          'form-builder-export-to-react-code', // button in export panel, menu item in "Form"
          'builder-test-toolbar',
          // initial part of form footer
          'builder-start-empty-form',
          'builder-start-here',
          'builder-menubar',
          // Start menu
          'builder-codesandbox',
          'builder-select-template',
          { plugin: 'builder-locales-selector', persist: false },
          // "Fields" menu
            'builder-frameworks',
            'builder-preview-size',
            { plugin: 'builder-toolbar-separator', parent: 'Fields' },
            'builder-add-field',
            { plugin: 'builder-toolbar-separator', parent: 'Fields' },
            'builder-complete-translations',
          // Form menu
            'builder-clear-form',
            { plugin: 'builder-toolbar-separator', parent: 'Form' },
            'builder-import-form',
            { plugin: 'builder-toolbar-separator', parent: 'Form' },
            'builder-share-form',
            hasFeatureFlag('publishForm') && 'builder-publish-form',
            hasFeatureFlag('playground') && 'builder-playground',
          'builder-save-project',
          'builder-close-button'
        ]}
      >
        {loading && !error && (
          <div
            className="loader"
            style={{
              textAlign: 'center',
              marginTop: '80px'
            }}
          ><AlchemicLoader/></div>
        )}
        {(loadingError || isError) && (
          <div style={{ marginTop: '60px' }}>
            <ErrorPlaceholder>
              An error occurred fetching the form or the form doesn't exist
            </ErrorPlaceholder>
          </div>
        )}
        {initialForm && !loading && (
          <Builder
            formSchema={initialForm}
            formFramework={framework}
            formSize={persistedSize}
            projectId={projectId}
            formId={formId}
            features={{
              shareForm: true,
              showProBadge: false,
              badgeContent: 'Pro',
              cancelButton: true,
              saveOptions: true,
              connectors: true
            }}
            onCancel={handleCancel}
            onChangeSize={handleSize}
          />
        )}
      </CodePlugProvider>
    </>
  );
};

export { ProjectFormPage };
