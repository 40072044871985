import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDrag } from 'react-dnd';

import Manifests from '../../../manifest';
import { fetchTemplates } from '../../../helpers';
import { useFormBuilderStore } from '../../../pages/builder/state';

import './component-card.scss';

const ControlCategories = {
  'general': 'Basic fields',
  'layout': 'Layout fields',
  'advanced': 'Advanced'
};

// TODO show only available components

const ComponentCard = ({ component }) => {

  const [{ isDragging }, drag] = useDrag({
    type: 'component',
    item: () => {
      return {
        id: component.name,
        componentName: component.name,
        dropType: 'component',
        template: component.template === true,
        templateId: component.templateId
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      //fieldId: field?.id
    })
  });

  const opacity = isDragging ? 0.5 : 1
  const Icon = component.icon;

  return (
    <div ref={drag} style={{ opacity }} className="lf-component-card">
      {Icon && <div className="icon"><Icon size={20}/></div>}
      {component.label}
    </div>
  );
};





const ComponentsPanel = ({ disabledComponents }) => {
  const [templates, setTemplates] = useState([]);
  const framework = useFormBuilderStore(state => state.framework);

  // fetch advanced fields (templates)
  useEffect(
    () => {
      const r = async () => {
        const templates = await fetchTemplates({ component: true });
        setTemplates(templates.templates);
      }
      r();
    },
    []
  );

  const hiddenComponents = Object.keys(Manifests)
    .reduce(
      (acc, componentName) => Manifests[componentName].hidden ? [...acc, componentName] : acc,
      disabledComponents ?? []
    );
  const unavailableComponents = Object.keys(Manifests)
    .reduce(
      (acc, componentName) => {
        const component = Manifests[componentName];
        if (
          componentName === 'forms' ||
          (_.isArray(component.frameworks) && !_.isEmpty(component.frameworks) && component.frameworks[0] === '*') ||
          (_.isArray(component.frameworks) && component.frameworks.includes(framework))
        ) {
          //console.log('unavailable component name', Manifests[componentName])
          return acc;
        }

        return [...acc, componentName];
      },
      []
    );

  let manifests = _.chain(Manifests)
    .omit(hiddenComponents)
    .omit(unavailableComponents)
    .groupBy('category')
    .value();

  // merge with advanced components
  if (Array.isArray(templates) && templates.length !== 0) {
    const items = templates.map(template => ({
      category: 'advanced',
      template: true,
      templateId: template.id,
      label: template.name,
      name: template.name,
      frameworks: template.frameworks,
      description: template.description
    }));

    manifests = {
      ...manifests,
      advanced: [
        ...(manifests.advanced ?? []),
        ...items
      ]
    };
  }

  const categories = Object.keys(manifests).filter(key => key !== 'undefined');


  return (
    <div className="lf-dnd-components">
      {categories.map(category => (
        <div className="category" key={category}>
          <div className="category-name">{ControlCategories[category]}</div>
          <div className="components">
            {manifests[category].map(component => (
              <ComponentCard component={component} key={component.name}/>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export { ComponentsPanel };
